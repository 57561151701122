.FinishMainContainer {
  display: flex;
  width: max-content;
  max-width: 98%;
  height: max-content;
  max-height: 98%;
  flex-direction: column;
  align-items: center;
  border: 2px solid black;
  border-radius: 15px;
  overflow: hidden;

  .ButtonBackToMain {
    width: 50vmin;
    height: 8vmin;
    font-size: 3.5vmin;
    margin-top: 1vmin;
    margin-bottom: 2vmin;
    background: lightgray;
    outline: none;
    text-align: center;
    font-weight: bold; }

  h3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    height: 3vmin;
    font-weight: bold;
    font-size: 3vmin;
    margin: 0 0 2vmin 0;

    p {
      margin-left: 2vmin;
      color: green; } }

  .RowStatesResults {
    display: flex;
    width: max-content;
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    height: 70vmin;
    overflow-x: scroll;
    outline: 2px solid black;
    background: lightslategrey;

    .StateResultBoardContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70vmin;
      height: calc(70vmin - 50px);
      margin-left: 10vmin;

      .StateResultBoard {
        display: flex;
        flex-direction: column;
        border: 2px solid black;
        border-radius: 5px;
        background: lightgrey;
        width: calc(70vmin - 50px);
        height: calc(70vmin - 50px);

        .StateResultOneRow {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          font-weight: bold;
          font-size: 5vmin; }

        .StateResultOneBlock {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid black;
            border-radius: 5px;
            outline: none;
            background: white; }

        .StateResultOneBlock-null {
          background: green; } } } } }
