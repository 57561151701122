.current_temp_input_matrix_board {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  border-radius: 5px;
  width: 82vmin;
  height: 82vmin;
  max-width: 38vw;
  max-height: 38vw;

  .one_row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;

    .one_block {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid black;
      border-radius: 5px;
      cursor: pointer;
      outline: none;
      font-weight: bold;
      font-size: 2.5vmin;

      &:hover {
        background: red; } }

    .one_block_empty {
      border: 2px dashed black;
      background: none;
      cursor: auto;

      &:hover {
        background: none; } } } }
