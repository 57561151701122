.InputMainContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .HMaintitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vmin;
    height: 10vmin;
    overflow: hidden;
    font-weight: bold;
    font-size: 4vmin;
    text-align: center; }

  .InsideMainContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: calc(100vw - 10px);
    height: max-content;
    max-height: 90vmin;
    outline: 4px solid black;

    .InsideSecondaryContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden; }

    .Htitle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 39vmin;
      height: 4vmin;
      overflow: hidden;
      font-weight: bold;
      font-size: 2vmin;
      text-align: center; }

    .title-selected-attr {
      width: max-content;
      max-width: 20vw;
      height: 6vmin;
      font-size: 2.5vmin; }

    .Matrixs {
      width: max-content;
      height: max-content;
      max-width: 40vw;
      max-height: 40vw;
      margin-bottom: 1vmin; }

    .SelectedAttributes {
      width: max-content;
      height: max-content;
      max-width: 20vw;

      .StartButton {
        display: flex;
        width: 20vmin;
        height: 7vmin;
        max-width: 90%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 3.5vmin;
        margin-top: 2vmin;
        margin-bottom: 1vmin;
        background: green;
        outline: none; }

      .InputSizeMatrixContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 2vmin;
        overflow: hidden;
        font-size: 3vmin;

        .InputSizeMatrix {
          display: flex;
          text-align: center;
          width: 10vmin;
          height: 10vmin;
          outline: none;
          margin-right: 5px;
          border: 2px solid black;
          overflow: hidden;
          font-size: 3vmin; } }

      .InputMaxNodesForAnalyse {
        display: flex;
        margin-top: 2vmin;
        width: 16vw;
        height: 10vmin;
        outline: none;
        border-radius: 15px;
        border: 2px solid black;
        text-align: center;
        font-size: 3vmin; }

      .SwitchMethod {
        display: flex;
        width: 16vw;
        height: 15vmin;
        max-width: 90%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 2px solid black;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 2vmin;

        .Method {
          display: flex;
          width: 8vw;
          height: 15vmin;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: none;
          outline: none;
          cursor: pointer;
          font-size: 2.7vmin;
          font-weight: bold; }

        .Method-active {
          background: greenyellow; } } } } }
